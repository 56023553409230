import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withLogin } from "./withLogin"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"
import ImageContent from "../../ImageContent"
import AccountNav from "../AccountNav"
import { LoginForm } from "./LoginForm"
import AccountSocials from "../AccountSocials"
import { GatsbyImageProps } from "../../../props/ImageProps"
import Breadcrumb from "../../Breadcrumb/Breadcrumb"

const Container = styled(StyledContainer)`
  ${tw`relative max-w-xl mx-auto bg-light md:bg-lighter`}
`

const Content = tw.div`
  max-w-59-2 md:max-w-72 mx-auto px-2-4 md:pl-4-8 md:pr-7-2 lg:pl-9-6 lg:pr-10-4 h-full flex justify-center flex-col py-8 md:py-0
`

const Nav = styled(AccountNav)`
  ${tw`mb-4-8 md:mb-2-4`}
`

const Form = styled(LoginForm)`
  ${tw`mb-1-6 md:mb-2-4`}
`

const Socials = styled(AccountSocials)`
  ${tw`mb-2-4 md:mb-3-2`}
`

export const Login = withLogin(({ title, image, paths }) => (
  <BackgroundContainer background={`light`}>
    <Container width={`full`}>
      <Breadcrumb paths={paths} />
      <ImageContent image={image} mobileHidden>
        <Content>
          <Nav />
          <Form />
          <Socials buttonText={title} />
        </Content>
      </ImageContent>
    </Container>
  </BackgroundContainer>
))

Login.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(GatsbyImageProps),
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}
