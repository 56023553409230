import React from "react"

export const withLogin = Component => ({ name = "Login", page, ...props }) => {
  const title = page?.title
  const image = page?.image?.asset?.fluid

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Login",
    },
  ]

  Component.displayName = name
  return <Component {...props} title={title} image={image} paths={paths} />
}
