import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withLoginForm } from "./withLoginForm"
import { StyledButton } from "../../Styled/Button"
import FormInput from "../../Form/FormInput"
import Icon from "../../Icon"

const Input = styled(FormInput)`
  ${tw`mb-2-4 px-0`}
`

const InputWrapper = tw.div`
  relative
`

const StyledIcon = styled(Icon)`
  ${tw`absolute right-0 top-0-6`}
`

const Submit = styled(StyledButton)`
  ${tw`w-full`}
`

const Addtional = tw.div`
  flex flex-row justify-between mb-6-4 md:mb-3-2
`

const Forgot = styled(Link)`
  ${tw`inline-block text-12 leading-1.67`}
`

const Error = tw.div`
  text-12 mb-2-4 text-orange
`

export const LoginForm = withLoginForm(({ data, handleChange, handleSubmit, loading, errors, className, showPassword, handleShowPassword }) => (
  <div className={className}>
    <form onSubmit={handleSubmit}>
      <Input name={`email`} type={`email`} placeholder={`Email`} value={data?.email} handleChange={handleChange} placeholderColor={`dark`} required />
      <InputWrapper>
        <Input
          name={`password`}
          type={showPassword ? `text` : `password`}
          placeholder={`Password`}
          placeholderColor={`dark`}
          value={data?.password}
          handleChange={handleChange}
          required
        />
        <StyledIcon name={!showPassword ? `show` : `hide`} size={`xsmall`} onClick={handleShowPassword} />
      </InputWrapper>
      {errors?.length > 0 &&
        errors?.filter(error => error?.field?.length && error?.field[1] === "password").map(error => <Error>{error?.message}</Error>)}
      <Addtional>
        <Forgot to={`/account/forgot`}>Forgot password?</Forgot>
      </Addtional>
      <Submit height={`56`} textSize={`16`} colour={`green`} disabled={loading}>
        Login
      </Submit>
    </form>
  </div>
))

LoginForm.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.any,
  className: PropTypes.string,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
}
