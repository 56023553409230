import React from "react"

import { useCustomerLogin, useShowPassword } from "../../../hooks/useCustomer"

export const withLoginForm = Component => ({ name = "LoginForm", ...props }) => {
  const { loginCustomer, data, setData, loading, errors } = useCustomerLogin()
  const { showPassword, setShowPassword } = useShowPassword()

  const handleSubmit = async event => {
    event.preventDefault()
    await loginCustomer({
      ...data,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  Component.displayName = name

  return (
    <Component
      {...props}
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      showPassword={showPassword}
      handleShowPassword={handleShowPassword}
    />
  )
}
